import React from 'react';
import pdf from '../../static/documents/BlueShieldIntake_210601.pdf';

export class Pdf2 extends React.Component {
  componentDidMount() {
    if (typeof window !== `undefined`) {
      window.open(pdf)
    }
  }

  render() {
    return <></>
  }
}

export default Pdf2;
